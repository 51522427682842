/*  eslint-disable */
import React from "react"
import { Helmet } from "react-helmet"

import Header from "src/componentsV2/sections/Header"
import { Footer } from "src/sections/landing"
import WidthWrapper from "src/components/widthWrapper"

const AdvocacyTermsPage = () => (
  <div className="leading-loose pb-24 pt-16 md:pt-20">
    <Helmet>
      <meta name="robots" content="all" />
      <title>AirMason | Advocacy & Referral</title>
      <meta name="description" content="" />
    </Helmet>
    <h2>Table of Contents</h2>
    <ol>
      <li>
        <a href="#advocacy-program">Advocacy Program</a>
      </li>
      <li>
        <a href="#referral-program">Referral Program</a>
      </li>
      <li>
        <a href="#additional-information">Additional Information</a>
      </li>
    </ol>
    <br />

    <div id="advocacy-program" className="data-process-content">
      <h2>Advocacy Program</h2>
      <p>
        Welcome to the AirMason Advocacy Program! We’re excited to have you join
        the AirMason Community, and looking forward to rewarding you for sharing
        feedback, content, referrals, and other helpful contributions. By
        joining, you agree to these simple Terms and Conditions.
      </p>
      <ol>
        <li className="font-bold">
          Participation:
          <ul className="disc font-normal">
            <li>By invitation only.</li>
            <li>
              Totally optional—participate as much as your schedule allows, as
              and when.
            </li>
            <li>
              Varying AirMason team members may contact you about opportunities
              to earn rewards.
            </li>
          </ul>
        </li>
        <li className="font-bold">
          Reward Activities:
          <ul className="disc font-normal">
            <li>
              Provide a client reference (written or verbal): 200 points*.
            </li>
            <li>Post a written testimonial online: 100 points.</li>
            <li>Participate in a survey: 100 points.</li>
            <li>Post a video testimonial online: 500 points.</li>
            <li>Participate in a case study: 1,000 points.</li>
            <li>
              Provide guest blog content: 5,000 points (limit 3 per month).
            </li>
          </ul>
        </li>
        <li className="font-bold">
          Reward Points:
          <ul className="disc font-normal">
            <li>
              Redeem points for gift cards (Amazon, DoorDash/UberEats,
              Starbucks, etc).
            </li>
            <li>Points are redeemable by active AirMason subscribers only.</li>
          </ul>
        </li>
        <p>*100 points = $25 value.</p>
        <li className="font-bold">
          Guidelines for Submitted Content:
          <ul className="disc font-normal">
            <li>Must be original, created by you—not AI-generated.</li>
            <li>Include your name, job title, and company name.</li>
            <li>No backlinks to HR services or products.</li>
            <li>Must be truthful and align with AirMason’s values.</li>
          </ul>
        </li>
      </ol>
    </div>
    <br />
    <div id="referral-program" className="data-process-content">
      <h2>Referral Program</h2>
      <p>
        Our Referral Program thanks you for bringing new clients to AirMason.
      </p>
      <ol>
        <li className="font-bold">
          Rewards:
          <ul className="disc font-normal">
            <li>
              Earn 2.5% value (redeemable in gift cards) on the first annual
              subscription of any successful referral (SLA signed)*.
            </li>
            <li>
              Your referral will also receive a 2.5% discount on their first
              year’s subscription.
            </li>
          </ul>
        </li>
        <li className="font-bold">
          Requirements:
          <ul className="disc font-normal">
            <li>Make a referral via direct written introduction</li>
            <li>Ensure the client knows about the referral.</li>
          </ul>
        </li>
        <p>
          * Gift card value is based on subscription cost, not including
          additional services like Creative Design or Copywriting.
        </p>
        <p>
          Please note: rewards type and value are subject to change at anytime,
          at AirMason’s discretion.
        </p>
        <p>
          For any questions about the Advocacy or Referral Programs, reach out
          to justin@airmason.com.
        </p>
      </ol>
    </div>
    <br />
    <div id="additional-information" className="data-process-content">
      <h2>Additional Information:</h2>
      <ul className="disc">
        <li>
          Please note: reward types and value are subject to change at anytime,
          at AirMason’s discretion.
        </li>
        <li>Rewards will be approved by AirMason prior to distribution.</li>
        <li>We may alter these rules at any time.</li>
      </ul>
    </div>
    <br />
  </div>
)

const Privacy = ({}) => {
  return (
    <React.Fragment>
      <Header />
      <WidthWrapper>
        <AdvocacyTermsPage />
      </WidthWrapper>
      <Footer />
    </React.Fragment>
  )
}

export default Privacy
